import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Menu } from 'semantic-ui-react'

import SwipeDimmer from 'components/app/swipe-dimmer'
import useProspect from 'context/prospect/use'
import Button from 'design-system/components/button'
import { Breakpoint } from 'styles/app/system'
import {
  EstimateGrid,
  GridContainer,
  MainColumn,
  MainContainer,
  OptionsColumn,
  Title,
} from 'styles/welcome/components/estimate'
import { NavMenu } from 'styles/welcome/components/menu'
import { ProgressBar } from 'styles/welcome/components/progress'
import WhatsIncludedModal from 'views/welcome/prospect/estimate/steps/results/details/whats-included/whats-included-modal'

type EstimatorSteps = 'layout' | 'dimensions' | 'info' | 'results'

type StepMap = Record<number, EstimatorSteps>

const stepMap: StepMap = {
  1: 'layout',
  2: 'dimensions',
  3: 'info',
  4: 'results',
}

interface EstimatorPageLayoutProps {
  customBackAction?: string
  handleNext?: () => void
  hideBackButton?: boolean
  hideBottomNav?: boolean
  hideDimmer?: boolean
  loading?: boolean
  mainContent: React.ReactNode
  sideContent?: React.ReactNode
  singleColumnLayout?: boolean
  step: EstimatorSteps
  title?: string
}

const EstimatorStepLayout = ({
  customBackAction,
  handleNext,
  hideBackButton = false,
  hideBottomNav = false,
  hideDimmer = false,
  loading = false,
  mainContent,
  sideContent,
  singleColumnLayout = false,
  step,
  title,
}: EstimatorPageLayoutProps) => {
  const isMobile = useMedia(Breakpoint.downFromComputer)
  const { prospectUrlComponent } = useProspect()
  const stepNumber = parseInt(
    Object.keys(stepMap).find((key) => stepMap[+key] === step) as string,
  )

  const [openModal, setOpenModal] = useState(false)

  const handleBack = () => {
    if (step === 'layout') return
    window.scroll(0, 0)

    // Skips info step if coming back from results
    const prevStep = stepMap[stepNumber - (stepNumber === 4 ? 2 : 1)]
    const baseUrl = `/welcome/${prospectUrlComponent}/estimate/`

    if (customBackAction) {
      navigate(customBackAction)
    } else {
      navigate(`${baseUrl}${prevStep}`)
    }
  }

  return (
    <>
      {step !== 'results' ? <ProgressBar total={4} value={stepNumber} /> : null}
      <EstimateGrid $hideBottomNav={hideBottomNav}>
        <GridContainer>
          <MainColumn
            $isSingleColumn={singleColumnLayout}
            computer={singleColumnLayout ? 16 : 11}
            tablet={16}
          >
            <MainContainer
              overflowY={isMobile && step !== 'results'}
              singleColumnLayout={singleColumnLayout}
            >
              {title ? (
                <>
                  <Title>
                    {isMobile ? (
                      <h4 className="no-margin">{title}</h4>
                    ) : (
                      <h2 className="no-margin">{title}</h2>
                    )}
                    <Button
                      kind="ghost"
                      fontAwesomeIcon="circle-plus"
                      text="What's Shown"
                      onClick={() => setOpenModal(true)}
                    />
                  </Title>
                </>
              ) : null}
              {mainContent}
              <WhatsIncludedModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
              />
            </MainContainer>
          </MainColumn>

          {!singleColumnLayout ? (
            <OptionsColumn computer={5} tablet={16}>
              {isMobile && !hideDimmer ? (
                <SwipeDimmer>{sideContent}</SwipeDimmer>
              ) : (
                sideContent
              )}
            </OptionsColumn>
          ) : null}
        </GridContainer>
      </EstimateGrid>

      {!hideBottomNav ? (
        <NavMenu borderless fixed="bottom">
          {!hideBackButton && (
            <>
              <Menu.Item className="up-mobile">
                <Button
                  disabled={step === 'layout'}
                  onClick={handleBack}
                  color="gray"
                  fontAwesomeIcon="arrow-left"
                  iconPosition="left"
                  text="Back"
                  kind="solid"
                  loading={loading}
                  size="medium"
                />
              </Menu.Item>
              <Menu.Item className="only-mobile">
                <Button
                  disabled={step === 'layout'}
                  onClick={handleBack}
                  kind="ghost"
                  color="dark"
                  loading={loading}
                  icon={
                    <FontAwesomeIcon
                      icon={['fal', 'chevron-left']}
                      style={{ fontSize: '16px' }}
                    />
                  }
                />
              </Menu.Item>
            </>
          )}
          {!customBackAction ? (
            <Menu.Menu position="right">
              <Menu.Item>
                <Button
                  loading={loading}
                  onClick={handleNext}
                  text="Continue"
                  fontAwesomeIcon="arrow-right"
                  kind="solid"
                  size={step === 'results' ? 'large' : 'medium'}
                />
              </Menu.Item>
            </Menu.Menu>
          ) : null}
        </NavMenu>
      ) : null}
    </>
  )
}

export default EstimatorStepLayout
